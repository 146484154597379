.profile
  padding: 100px 0
  &__wrapper
    max-width: 400px
    width: 100%
    padding: 0 15px
    margin: 0 auto
  &__content
    margin-top: 30px
  &__form
    width: 100%
  &__btns
    .btn
      margin-bottom: 15px
      &:last-child
        margin-bottom: 0
    .btn--md
      max-width: 100%


@media(min-width: $sm)
  .profile
    &__wrapper
      max-width: 1100px
      display: flex
    &__content
      flex-grow: 1
      margin-top: 0
    &__form
      max-width: 410px
      width: 100%
    &__btns
      .btn
        margin-right: 15px
        margin-bottom: 0
        &:last-child
          margin-right: 0
      .btn--md
        max-width: 220px
      .btn--full-width
        max-width: 100%

@media(min-width: $md)
  .profile
    padding: 140px 0
    &__btns
      .btn--md
        max-width: 250px
      .btn--full-width
        max-width: 100%